import rest from '@exchange/libs/utils/rest/src';

const URL = '/agreement';

export default function Resource({ base }) {
  const futuresDisclaimerUrl = `${URL}/futures-disclaimer`;

  return {
    getFuturesDisclaimer: async (): Promise<{ date: string } | undefined> => {
      try {
        return await rest.request(
          {
            url: futuresDisclaimerUrl,
            base,
          },
          {
            useSubaccount: false,
          },
        );
      } catch (e) {
        if ((e as { status: number }).status === 404) {
          return undefined;
        }

        throw e;
      }
    },
    setFuturesDisclaimer: async (): Promise<void> =>
      rest.request(
        {
          url: futuresDisclaimerUrl,
          base,
          method: 'post',
        },
        {
          useSubaccount: false,
        },
      ),
  };
}
