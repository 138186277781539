import { type IncomingCurrency } from '@exchange/libs/utils/currency/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/currencies';

export default function CurrenciesResource({ base }) {
  return {
    get(): Promise<Array<IncomingCurrency>> {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
  };
}
